<template>
  <div class="map-expand-button">
    <a
      href="#"
      class="level expand"
      aria-label="map Expand"
      @click.prevent="toggleModal()"
    >
      <span class="icon expand-btn">
        <i class="fas fa-expand" />
      </span>
    </a>
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
export default {
  methods: {
    toggleModal() {
      EventBus.$emit('TOGGLE_MAP_MODAL');
    }
  }
};
</script>
