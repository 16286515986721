<template>
  <section
    v-if="!raqmodel.tour.useWidget"
    class="datesprices section"
  >
    <div class="container is-fullwidth">
      <div class="level dates-prices-header">
        <div class="column dates-prices-header__info is-paddingless">
          <div class="columns is-marginless dates-prices-header__items is-full">
            <div class="column dates-prices-header-left is-full-mobile is-4-tablet px-0">
              <div class="dates-prices-header__title is-3-tablet">
                <h2 class="header">
                  Dates &amp; Prices
                </h2>
                <div
                  class="dates-prices-results-count"
                  :class="{
                    disabled: localappdata.apiError
                  }"
                >
                  Results:
                  <span v-if="!localappdata.dataAllTotal && !localappdata.apiError">loading...</span>
                  <span
                    v-if="localappdata.dataAllTotal"
                    v-text="
                      model.labels.toggleDefinite && !filtersClicked
                        ? localappdata.definiteDepartureCount
                        : localappdata.visibleList.length
                    "
                  />
                  <span v-if="localappdata.dataAllTotal">/ {{ localappdata.dataAllTotal }}</span>
                  <span v-if="localappdata.apiError">0</span>
                </div>
              </div>
            </div>

            <div class="dates-prices-filters column is-8-tablet is-paddingless">
              <div
                v-if="!model.labels.hideDepartureDatesSwitch"
                class="field dates-prices-toggle is-paddingless is-full-mobile is-flex"
              >
                <label
                  class="switch inline-center is-relative"
                  for="defineDepartures"
                >
                  <input
                    id="defineDepartures"
                    v-model="model.labels.toggleDefinite"
                    tabindex="0"
                    type="checkbox"
                    aria-label="Definite Departures Only"
                    name="defineDepartures"
                    :disabled="model.labels.hideDepartureDatesSwitch"
                    @click="populateVisibleList()"
                  >
                  <div class="slider round" />
                </label>
                <p
                  :class="{ filterChecked: filterCheckbox }"
                  class="filterLabel"
                >
                  <span class="definiteDeparture">Definite Departures Only</span>
                  <span
                    data-tooltip-text="We guarantee our trips are &quot;Definite&quot; and will depart (weather and other conditions dependent, of course) as soon as enough guests are booked."
                    data-tooltip-position="top"
                    class="icon tooltip"
                  ><i class="fas fa-info-circle" /></span>
                </p>
              </div>
              <div
                v-if="!model.labels.hideDepartureDatesSwitch"
                class="pecial-offers-filter field dates-prices-toggle is-paddingless is-full-mobile is-flex"
              >
                <label
                  class="switch inline-center is-relative"
                  for="specialOffers"
                  aria-labelledby="special offers"
                >
                  <input
                    id="specialOffers"
                    v-model="model.labels.toggleSpecialOffers"
                    type="checkbox"
                    aria-label="Special Offers"
                    name="specialOffers"
                    @click="populateVisibleList()"
                  >
                  <div class="slider round" />
                </label>
                <p
                  class="filterLabel"
                  :class="{ filterChecked: filterCheckbox }"
                >
                  <span class="specialOffers">Special Offers</span>
                </p>
              </div>
              <div class="is-paddingless is-full-mobile filter">
                <dates-prices-filter-months
                  :model="model"
                  :months="localappdata.availableMonths"
                  :selectedmonths="localappdata.userActions.selectedMonths"
                  @update-selected-months-array="updateSelectedMonths($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="shouldDisplayAirfareText"
      class="datesprices__excludesairfare-box"
    >
      <i class="datesprices__excludesairfare-icon fas fa-info-circle" />
      <span
        class="datesprices__excludesairfare-text"
        v-html="airfareText"
      />
    </div>
    <div
      v-cloak
      id="pricesTable"
      class="prices-table dates-prices-item-container"
    >
      <div
        v-show="localappdata.visibleList.length"
        class="dates-prices-table is-fullwidth"
      >
        <ul class="dates-prices-table-header">
          <li class="dates-prices__startDate">
            <span>{{
              model.labels.startDateText ? model.labels.startDateText : 'Start date'
            }}</span>
            <span
              v-if="model.labels.startDateToolTip"
              class="icon tooltip"
              :data-tooltip-text="model.labels.startDateToolTip"
              data-tooltip-position="right"
            >
              <i class="fas fa-info-circle" />
            </span>
          </li>
          <li
            v-if="!model.labels.hideEndDateHeading"
            class="dates-prices__endDate"
          >
            <span>{{ model.labels.endDateText ? model.labels.endDateText : 'End date' }}</span>
            <span
              v-if="model.labels.endDateToolTip"
              class="icon tooltip"
              :data-tooltip-text="model.labels.endDateToolTip"
              data-tooltip-position="top"
            >
              <i class="fas fa-info-circle" />
            </span>
          </li>
          <li
            v-if="!model.labels.hideDealsColumn"
            class="dates-prices__deals"
          >
            <span>Deals</span>
            <span
              v-if="model.labels.tasCurrentDealsTooltip"
              class="icon tooltip"
              :data-tooltip-text="model.labels.tasCurrentDealsTooltip"
              data-tooltip-position="top"
            >
              <i class="fas fa-info-circle" />
            </span>
          </li>
          <li
            v-if="!model.labels.hideDepartureStatusColumn"
            class="dates-prices__departure"
          >
            <span>Departure <br>
              Status</span>
          </li>
          <li
            v-if="!model.labels.hideCurrentDealPriceColumn"
            class="currentDealsPrice dates-prices__currentPrice"
          >
            <span>Price with <br>
              current deals</span>
          </li>
          <li
            v-if="localappdata.siteDiscount"
            class="dates-prices__exclusivePrice"
          >
            <span
              class="dates-prices-exclusive-header"
              v-html="model.labels.exclusivePriceLabel"
            />
          </li>
          <li class="dates-prices__easyQuote">
            <span>Get <br>
              Easy Quote</span>
          </li>
          <li
            v-if="
              model.labels.canBook &&
                model.labels.isCostco &&
                model.labels.isLoggedIn &&
                !model.labels.isGB &&
                model.labels.membershipDetails &&
                !raqmodel.tour.isCmsTour
            "
            class="book-now dates-prices_bookNow"
          >
            <span>Book now</span>
          </li>
          <li
            v-if="model.labels.canBook && !model.labels.isCostco && !raqmodel.tour.isCmsTour"
            class="book-now dates-prices_bookNow"
          >
            <span>Book now</span>
          </li>
        </ul>
        <div
          class="dates-prices-table-body"
          :class="{ bookNow: model.labels.canBook }"
        >
          <vue-dates-price-row
            v-for="item in localappdata.visibleList
              .slice(0, localappdata.maxShownItems)
              ?.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())"
            :key="item.index"
            :model="model"
            :row="item"
            :raqmodel="raqmodel"
            :localappdata="localappdata"
          />
        </div>
      </div>
      <div
        v-if="localappdata.isLoadingFresh"
        class="dates-prices-item-loader"
      >
        <div
          v-if="!localappdata.apiError"
          class="loader-bar inverse"
        />
        <h3
          v-if="localappdata.apiError"
          class="has-text-centered has-text-white"
        >
          <br>Trips are unable to load at the moment, please refresh the page or try again later.
        </h3>
      </div>
      <div
        v-if="localappdata.maxShownItems < localappdata.visibleList.length"
        class="has-text-centered datesprices-button-holder"
      >
        <button
          class="cta"
          :class="{ 'is-loading': localappdata.isLoadingMore }"
          @click="showmoreclicked()"
          v-text="!localappdata.isLoadingMore ? 'Show more...' : ''"
        />
      </div>
    </div>
    <div
      v-if="!localappdata.isLoadingFresh && !localappdata.visibleList.length"
      class="has-text-centered datesprices-noresults-holder"
    >
      <h3
        v-text="
          model.labels.noDefiniteDeparturesText
            ? model.labels.noDefiniteDeparturesText
            : 'No results. Try switching the Definite Departures Only'
        "
      />
    </div>
  </section>
</template>
<script>
import VueDatesPriceRow from './dates-prices-row.vue';
import DatesPricesFilterMonths from './dates-prices-filters/dates-prices-filter-months.vue';
export default {
  components: {
    'vue-dates-price-row': VueDatesPriceRow,
    'dates-prices-filter-months': DatesPricesFilterMonths
  },
  props: ['model', 'raqmodel', 'localappdata'],
  data() {
    return {
      noResults: false,
      filtersClicked: false,
      filterCheckbox: false
    };
  },
  computed: {
    currentDealPriceFlag: function () {
      if (this.airfareincluded) {
        return this.row.price + '*';
      }
      return this.row.price;
    },
    discountedPriceFlag: function () {
      if (this.airfareincluded) {
        return this.row.discountedPrice + '*';
      }
      return this.row.discountedPrice;
    },
    noToggle() {
      return this.model.labels.hideDepartureDatesSwitch;
    },
    airfareText() {
      return this.model.labels.airfareIncluded
        ? this.model.labels.includesAirfareText
        : this.model.labels.excludesAirfareText;
    },
    shouldDisplayAirfareText() {
      return !!this.airfareText;
    }
  },
  mounted() {
    this.toggleDropdownFilters();
  },
  methods: {
    showmoreclicked: function () {
      this.$emit('triggerloadmore');
    },
    checkIfNoResults() {
      if (!this.model.apiUrl) {
        let definiteDepartureArray = [];
        this.model.availabilities.data.forEach((item) => {
          definiteDepartureArray.push(item.definiteDeparture);
        });
        function checkAllFalse(item) {
          return item == false;
        }
        if (definiteDepartureArray.every(checkAllFalse)) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }
      }
    },
    updateSelectedMonths(updatedList) {
      this.localappdata.userActions.selectedMonths = updatedList;
      this.populateVisibleList();
    },
    populateVisibleList() {
      this.localappdata.isLoadingMore = true;
      setTimeout(() => {
        this.filtersClicked = true;
        let visibleListArray = [];
        for (let i = 0; i < this.localappdata.dataAll.length; i++) {
          var includedInMonths =
            this.localappdata.userActions.selectedMonths.length == 0 ||
            this.localappdata.userActions.selectedMonths.includes(
              this.localappdata.dataAll[i].startMonth +
                ' ' +
                this.localappdata.dataAll[i].startDateData.slice(0, 4)
            );

          if (includedInMonths) {
            if (
              this.model.labels.hideDepartureDatesSwitch ||
              ((!this.model.labels.toggleDefinite ||
                (this.model.labels.toggleDefinite &&
                  this.localappdata.dataAll[i].definiteDeparture)) &&
                (!this.model.labels.toggleSpecialOffers ||
                  (this.model.labels.toggleSpecialOffers &&
                    this.localappdata.dataAll[i].specialOffer)))
            ) {
              visibleListArray.push(this.localappdata.dataAll[i]);
            }
          }
        }
        this.localappdata.visibleList = visibleListArray;
        this.localappdata.isLoadingMore = false;
      }, 200);
    },
    toggleDropdownFilters() {
      const dropdownEls = $('.dropdown');
      dropdownEls.click(function (event) {
        event.stopPropagation();
        $(this).toggleClass('is-active');
        $(document).click(function (event) {
          dropdownEls.removeClass('is-active');
        });
      });
    }
  }
};
</script>
