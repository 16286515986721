<template>
  <div
    id="mapmodal"
    class="modal modal-geq modal-map"
    :class="{ 'is-active': active }"
  >
    <div
      class="modal-background"
      @click.prevent="closeForm()"
    />
    <div
      v-if="!mapTypeImage"
      class="modal-card modal-map modal-map-image"
      :style="{ backgroundImage: 'url(' + mapurl + ')' }"
    />

    <img
      v-if="mapTypeImage"
      :src="mapurl"
      class="fullimage"
      :class="tourtype.isV4Tour ? 'v4map' : tourtype.isC1Tour ? 'c1Map' : ''"
      :alt="itinerarymodel?.mapImageAltTag"
    >

    <a
      href="#"
      aria-label="close"
      class="modal-card-head modal-card-close"
      @click.prevent="closeForm()"
    >
      <i class="fa fa-times fa-3x" />
    </a>
  </div>
</template>
<script>
import EventBus from '../../vue-functions/event-bus';
import { closeModal } from '../../modals/closeModal';
export default {
  props: ['mapurl', 'mapTypeImage', 'itinerarymodel', 'tourtype'],
  data() {
    return {
      active: false,
      modal: false
    };
  },
  created: function () {
    EventBus.$on('TOGGLE_MAP_MODAL', () => {
      document.documentElement.classList.add('is-clipped');
      this.show();
    });
  },
  mounted() {
    this.closeModal(this.closeForm);
  },
  methods: {
    show() {
      this.active = true;
    },
    closeForm() {
      document.documentElement.classList.remove('is-clipped');
      this.active = false;
    },
    closeModal
  }
};
</script>
